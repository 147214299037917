
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import View from "@/assets/svg/view.svg?inline";
import Edit from "@/assets/svg/edit.svg?inline";
import Delete from "@/assets/svg/delete.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import Picture from "@/assets/test/picture.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import AddInstructor from "@/assets/svg/add-instructor.svg?inline";
import Pagination from "@/components/molecules/Pagination.vue";
import vClickOutside from "click-outside-vue3";
import Calendar from "@/components/molecules/Calendar.vue";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import CreateStudent from "@/assets/svg/create-student-name.svg?inline";
import EmailIcon from "@/assets/svg/create-student-email-icon.svg?inline";
import PhoneIcon from "@/assets/svg/login-phone-icon.svg?inline";
import UploadPhoto from "@/assets/svg/upload-photo.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import Pluse from "@/assets/svg/pluse-blue.svg?inline";
import Minuse from "@/assets/svg/minuse-blue.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import authHeader from "@/services/auth-header";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "Students",
  data() {
    return {
      page: 1,
      studentsMeta: null,
      studentsData: {},
      instructorsData: {},
      attachInstructorsData: {},
      inviteInstructorsData: [],
      errorstudents: null,
      isModalChooseInstructorNewStudent: false,
      isModalCopyCode: false,
      searchData: "",
      searchDataInstructors: "",
      sortData: "",
      instructorName: "",
      generateStateCode: null,
      selectedNewDate: null,
      selectedNewDateSubsqribe: null,
      isCalendarVisible: false,
      isCalendarSubsqribeVisible: false,
      showSubscription: null,
      subscriptionEnds: null,
      showNoSubscription: null,
      withtoutInstructor: null,
      justParents: null,
      copied: false,
      errorAttachInstructor: null,
      activeStep: "1",
      isPreview: false,
      errorInstructor: null,
      fullName: "",
      phone: null,
      email: null,
      studentData: {},
      additionalStudentData: ["1", "2"],
      isAdditional: false,
      studentParentId: 0,
      expandedRows: {},
      newStudentId: "",
      studentSetPasswordLink: null,
      showJustParentData: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    AdminPanelNavMenu,
    View,
    Edit,
    Delete,
    Search,
    Picture,
    DefaultPopup,
    SortArrow,
    Pagination,
    Calendar,
    AddInstructor,
    SelectArrow,
    Form,
    Field,
    CreateStudent,
    EmailIcon,
    PhoneIcon,
    UploadPhoto,
    AlertBlock,
    PhotoMock,
    Pluse,
    Minuse,
  },
  watch: {
    searchData() {
      this.getStudents();
      this.page = 1;
    },
    searchDataInstructors() {
      this.getInstructors();
      this.page = 1;
    },
  },
  mounted() {
    this.getStudents();
    this.getInstructors();
  },
  computed: {
    filteredStudents() {
      if (this.showJustParentData) {
        return this.studentsData.filter(
          (student) => student.children && student.children.length > 0
        );
      }
      return this.studentsData;
    },
  },
  methods: {
    getStudents(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "id";
      }
      params = searchParam + "&sort=" + sort;
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.studentsData = res.data;
          this.studentsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getInstructors(params) {
      params = "?search=" + this.searchDataInstructors;
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.instructorsData = res.data;
          this.instructorsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    viewStudent(id) {
      this.$router.push("/admin/student?student=" + id);
    },
    editstudent(id) {
      this.$router.push("/admin/edit-student?student=" + id);
    },
    openPopupChoosenInstructorNewStudent(id) {
      this.studentId = id;
      this.isModalChooseInstructorNewStudent = true;
    },
    handleCloseModalChooseInstructorNewStudent() {
      this.isModalChooseInstructorNewStudent = false;
    },
    openPopupCopyCode() {
      this.isModalCopyCode = true;
    },
    handleCloseModalCopyCode() {
      this.isModalCopyCode = false;
    },
    isCheckedInstructor(id) {
      return this.attachInstructorsData?.some(
        (instructor) => instructor.id === id
      );
    },
    attachInstructors(id) {
      if (!Array.isArray(this.attachInstructorsData)) {
        this.attachInstructorsData = [];
      }
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      );
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1);
      } else {
        const instructorToAdd = this.instructorsData?.find(
          (instructor) => instructor.id === id
        );
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd);
        }
      }
      const data = {
        id: this.studentId,
        instructors: [id],
      };
      this.$store.dispatch("students/attachInstructors", data).then(
        () => {
          this.getStudents();
        },
        (error) => {
          this.errorAttachInstructor =
            error.response?.data?.message || "Error attaching instructor";
        }
      );
    },
    unAttachInstructors(id) {
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      );
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1);
      } else {
        const instructorToAdd = this.instructorsData.find(
          (instructor) => instructor.id === id
        );
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd);
        }
      }
      const ids = [];
      ids.push(id);
      const data = {
        id: this.studentId,
        instructors: ids,
      };
      this.$store.dispatch("students/detachInstructors", data).then(
        () => {
          this.getStudents();
        },
        (error) => {
          this.errorAttachInstructor = error.response.data.message;
        }
      );
    },
    generateInviteCodeStudent() {
      const data = {
        instructors: this.inviteInstructorsData,
      };
      this.$store.dispatch("students/setInviteCode", data).then(
        (res) => {
          this.generateStateCode = res.data;
          this.handleCloseModalChooseInstructorNewStudent();
          this.openPopupCopyCode();
        },
        (error) => {
          this.errorInviteCode = error.response.data.message;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getStudents();
    },
    isChecked(id) {
      if (this.instructorName) {
        return this.instructorName.some((selected) => selected.id === id);
      }
    },
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.studentSetPasswordLink);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } catch (error) {
        console.error("Failed to copy text: ", error);
      }
    },
    selectInstructors(id) {
      this.inviteInstructorsData.push(id);
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = date.getDate();
      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        date
      );
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    selectDateFillSubsqribe(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    toggleCalendarSubsqribe() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendarSubsqribe() {
      this.isCalendarSubsqribeVisible = false;
    },
    resetFilters() {
      window.location.reload();
    },
    checkedShowSubscription() {
      let showSubscriptionNew = 1;
      if (!this.showSubscription === false) {
        showSubscriptionNew = 0;
      }
      this.filters =
        this.filters + `&filter[inactive_subscription]=${showSubscriptionNew}`;
      this.getPaymentLogs();
    },
    nextStep(step) {
      this.activeStep = step;
      this.errorInstructor = null;
    },
    prewStep(step) {
      this.activeStep = step;
    },
    createStudentStep(step) {
      this.$store
        .dispatch("students/getLinkSetPassword", this.newStudentId)
        .then(
          (res) => {
            this.activeStep = step;
            this.studentSetPasswordLink = res.set_password_link;
          },
          (error) => {
            this.errorAttachInstructor = error.response.data.message;
          }
        );
    },
    handleFileUpload() {
      this.isPreview = false
      this.imagePreview = null
      this.errorInstructor = null;
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    showAdditionalStudent(id) {
      this.expandedRows = {
        ...this.expandedRows,
        [id]: true,
      };
    },
    hideAdditionalStudent(id) {
      this.expandedRows = {
        ...this.expandedRows,
        [id]: false,
      };
    },
    isRowExpanded(id) {
      return !!this.expandedRows[id];
    },
    createStudent() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("full_name", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("instructor_ids[]", this.inviteInstructorsData);
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      this.axios
        .post(API_URL + "admin/students", formData, {
          headers: authHeader(),
        })
        .then(
          (res) => {
            this.studentData = res.data.data;
            this.newStudentId = this.studentData.id;
            this.nextStep("3");
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
            console.log(
              this.errorInstructor.image,
              "this.errorInstructor.image"
            );
            if (
              this.activeStep === "1" &&
              this.errorInstructor.image === undefined &&
              this.errorInstructor.full_name === undefined &&
              this.errorInstructor.email === undefined &&
              this.errorInstructor.phone === undefined
            ) {
              this.nextStep("2");
            }
          }
        );
    },
    showJustParent() {
      this.showJustParentData = !this.showJustParentData;
    },
  },
});
