import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, normalizeClass as _normalizeClass, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "students" }
const _hoisted_2 = { class: "students__top-line-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "students__top-line-wrap-right"
}
const _hoisted_4 = {
  key: 1,
  class: "students__top-line-wrap-right"
}
const _hoisted_5 = { class: "students__wrap" }
const _hoisted_6 = { class: "instructors__mobile-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "students__mobile-container" }
const _hoisted_9 = {
  key: 0,
  class: "students__title"
}
const _hoisted_10 = { class: "students__value" }
const _hoisted_11 = {
  key: 1,
  class: "students__title"
}
const _hoisted_12 = { class: "students__value" }
const _hoisted_13 = {
  key: 2,
  class: "students__title"
}
const _hoisted_14 = {
  key: 3,
  class: "students__value"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 4,
  class: "students__title"
}
const _hoisted_17 = { class: "main-table" }
const _hoisted_18 = { class: "students__info-block" }
const _hoisted_19 = { class: "students__info-block-wrap" }
const _hoisted_20 = { class: "students__block" }
const _hoisted_21 = { class: "students__value" }
const _hoisted_22 = { class: "students__block" }
const _hoisted_23 = { class: "students__value" }
const _hoisted_24 = { class: "students__block" }
const _hoisted_25 = {
  key: 0,
  class: "students__value"
}
const _hoisted_26 = {
  key: 1,
  class: "students__value"
}
const _hoisted_27 = {
  key: 0,
  class: "students__title"
}
const _hoisted_28 = { class: "students__value" }
const _hoisted_29 = { class: "students__controls" }
const _hoisted_30 = { class: "students__data-range-wrap" }
const _hoisted_31 = { class: "main-table__tabs" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { class: "main-table__active-status" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = {
  key: 1,
  class: "main-table__mobile-wrap"
}
const _hoisted_37 = { class: "main-table__head" }
const _hoisted_38 = { class: "main-table__head-id" }
const _hoisted_39 = { class: "main-table__sort-block" }
const _hoisted_40 = { class: "main-table__head-big-tr" }
const _hoisted_41 = { class: "main-table__sort-block" }
const _hoisted_42 = { class: "main-table__head-big-tr" }
const _hoisted_43 = { class: "main-table__sort-block" }
const _hoisted_44 = { class: "main-table__head-big-tr" }
const _hoisted_45 = { class: "main-table__sort-block" }
const _hoisted_46 = { class: "main-table__head-big-tr" }
const _hoisted_47 = { class: "main-table__sort-block" }
const _hoisted_48 = { class: "main-table__head-big-tr" }
const _hoisted_49 = { class: "main-table__sort-block" }
const _hoisted_50 = { class: "main-table__head-big-tr" }
const _hoisted_51 = { class: "main-table__sort-block" }
const _hoisted_52 = { class: "main-table__body-id" }
const _hoisted_53 = { class: "main-table__body-big-tr" }
const _hoisted_54 = { class: "main-table__body-big-tr" }
const _hoisted_55 = { class: "main-table__body-big-tr" }
const _hoisted_56 = { class: "main-table__body-big-tr" }
const _hoisted_57 = { class: "main-table__body-big-tr" }
const _hoisted_58 = { key: 0 }
const _hoisted_59 = {
  key: 1,
  class: "main-table__body-big-tr-hover"
}
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { class: "main-table__body-big-tr" }
const _hoisted_62 = {
  key: 2,
  class: "main-table__expiration-date"
}
const _hoisted_63 = { class: "students__container" }
const _hoisted_64 = { class: "students__data-range-wrap" }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { key: 0 }
const _hoisted_67 = {
  key: 1,
  class: "students__error"
}
const _hoisted_68 = {
  key: 0,
  class: "students__unsubscribe-button unsubscribeActive"
}
const _hoisted_69 = { key: 3 }
const _hoisted_70 = { class: "main-table__expiration-date" }
const _hoisted_71 = { class: "students__container" }
const _hoisted_72 = {
  key: 4,
  class: "main-table__mobile-wrap"
}
const _hoisted_73 = { class: "main-table__head" }
const _hoisted_74 = { class: "main-table__head-big-tr" }
const _hoisted_75 = { class: "main-table__sort-block" }
const _hoisted_76 = { class: "main-table__body-id" }
const _hoisted_77 = { class: "main-table__body-big-tr" }
const _hoisted_78 = {
  key: 5,
  class: "main-table__mobile-wrap"
}
const _hoisted_79 = { class: "students__container" }
const _hoisted_80 = { class: "calendar__data-range-wrap" }
const _hoisted_81 = {
  key: 0,
  class: "main-table__mobile-wrap"
}
const _hoisted_82 = { class: "main-table__head" }
const _hoisted_83 = { class: "main-table__head-big-tr" }
const _hoisted_84 = { class: "main-table__sort-block" }
const _hoisted_85 = { class: "main-table__head-big-tr" }
const _hoisted_86 = { class: "main-table__sort-block" }
const _hoisted_87 = { class: "main-table__body-id" }
const _hoisted_88 = { class: "main-table__body-big-tr" }
const _hoisted_89 = { class: "students__popup-buttons" }
const _hoisted_90 = { class: "students__popup-title" }
const _hoisted_91 = { class: "students__popup-buttons" }
const _hoisted_92 = { class: "students__popup-buttons" }
const _hoisted_93 = { class: "students__popup-buttons" }
const _hoisted_94 = { class: "students__popup-title student__popup-title-unsubscribe" }
const _hoisted_95 = { class: "students__popup-buttons" }
const _hoisted_96 = { class: "students__choose-instructor students__create-student-wrap" }
const _hoisted_97 = {
  key: 0,
  class: "students__create-student-steps"
}
const _hoisted_98 = {
  key: 1,
  class: "students__create-student-second-step"
}
const _hoisted_99 = { class: "students__form" }
const _hoisted_100 = { class: "students__images-container" }
const _hoisted_101 = { class: "students__photo-block" }
const _hoisted_102 = ["src"]
const _hoisted_103 = {
  key: 1,
  class: "students__upload-photo"
}
const _hoisted_104 = { key: 0 }
const _hoisted_105 = { class: "form-group" }
const _hoisted_106 = { class: "students__input-wrap" }
const _hoisted_107 = { key: 0 }
const _hoisted_108 = {
  key: 2,
  class: "students__create-student-second-step"
}
const _hoisted_109 = { class: "students__search-wrap students__search-choose-instructor" }
const _hoisted_110 = { class: "students__choose-instructor-wrap" }
const _hoisted_111 = ["for"]
const _hoisted_112 = ["id", "name", "onClick"]
const _hoisted_113 = { key: 0 }
const _hoisted_114 = {
  key: 3,
  class: "students__create-student-second-step"
}
const _hoisted_115 = { class: "students__photo-mock-wrap" }
const _hoisted_116 = ["src", "alt"]
const _hoisted_117 = { class: "students__value" }
const _hoisted_118 = {
  key: 4,
  class: "students__create-student-second-step students__create-student-last-step"
}
const _hoisted_119 = { class: "students__popup-link-text" }
const _hoisted_120 = { class: "students__choose-instructor" }
const _hoisted_121 = {
  key: 0,
  class: "students__create-student-second-step"
}
const _hoisted_122 = { class: "form-group" }
const _hoisted_123 = { class: "students__input-wrap" }
const _hoisted_124 = { key: 0 }
const _hoisted_125 = { class: "form-group" }
const _hoisted_126 = { class: "students__input-wrap" }
const _hoisted_127 = { key: 0 }
const _hoisted_128 = {
  key: 1,
  class: "students__create-student-second-step students__create-student-last-step"
}
const _hoisted_129 = {
  key: 7,
  class: "students__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdditionalStudentIcon = _resolveComponent("AdditionalStudentIcon")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_BlockUser = _resolveComponent("BlockUser")!
  const _component_UnblockUser = _resolveComponent("UnblockUser")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _component_UploadPhoto = _resolveComponent("UploadPhoto")!
  const _component_CreateStudent = _resolveComponent("CreateStudent")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Search = _resolveComponent("Search")!
  const _component_PhoneIcon = _resolveComponent("PhoneIcon")!
  const _component_EmailIcon = _resolveComponent("EmailIcon")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/admin/students",
        class: "students__back"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ArrowBack)
        ]),
        _: 1
      }),
      (this.studentData?.is_child)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "students__additional-student-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPopupConvertToIndividualStudent()))
            }, " Convert to Student "),
            _cache[53] || (_cache[53] = _createElementVNode("div", { class: "students__account-type students__account-type-additional" }, " Additional student ", -1))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "students__additional-student-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPopupChoosenInstructorNewStudent()))
            }, [
              _createVNode(_component_AdditionalStudentIcon),
              _cache[54] || (_cache[54] = _createTextVNode("Additional student "))
            ]),
            _cache[55] || (_cache[55] = _createElementVNode("div", { class: "students__account-type" }, "student", -1))
          ]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          (this.studentData.image === null)
            ? (_openBlock(), _createBlock(_component_PhotoMock, {
                key: 0,
                class: "students__photo"
              }))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: this.studentData.image,
                class: "students__photo"
              }, null, 8, _hoisted_7))
        ]),
        _createElementVNode("div", _hoisted_8, [
          (this.studentData?.level?.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Level "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, _toDisplayString(this.studentData?.level?.title), 1),
          (this.studentData?.skill?.skill_order)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Skill "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, _toDisplayString(this.studentData?.skill?.skill_order), 1),
          (this.studentData?.skill?.skill_order)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Skill progress "))
            : _createCommentVNode("", true),
          (this.studentData?.skill)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createTextVNode(_toDisplayString(this.studentData?.skill?.confirmations?.length) + " ", 1),
                (
                this.studentData?.skill?.confirmations?.length === undefined
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, "0"))
                  : _createCommentVNode("", true),
                _cache[56] || (_cache[56] = _createTextVNode("/3 "))
              ]))
            : _createCommentVNode("", true),
          (this.studentData.instructors)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Instructor "))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData.instructors, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "students__value-instrucrot",
              key: item.id
            }, _toDisplayString(item.full_name), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _cache[57] || (_cache[57] = _createElementVNode("div", { class: "students__title" }, "Full Name", -1)),
              _createElementVNode("div", _hoisted_21, _toDisplayString(this.studentData.full_name), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _cache[58] || (_cache[58] = _createElementVNode("div", { class: "students__title" }, "Email", -1)),
              _createElementVNode("div", _hoisted_23, _toDisplayString(this.studentData.email), 1)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _cache[59] || (_cache[59] = _createElementVNode("div", { class: "students__title" }, "Phone Number", -1)),
              (this.studentData?.phone?.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(this.studentData?.phone?.value), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_26, "-"))
            ]),
            _createElementVNode("div", null, [
              (this.studentData?.children?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, " Additional students "))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData?.children, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [
                  _createElementVNode("div", _hoisted_28, _toDisplayString(item.full_name), 1)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", {
              class: "students__button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editstudent && _ctx.editstudent(...args)))
            }, [
              _createVNode(_component_Edit)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_30, [
              _createElementVNode("div", {
                class: "students__button",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendarStydentBanned && _ctx.toggleCalendarStydentBanned(...args)))
              }, [
                (!this.isStydentBanned)
                  ? (_openBlock(), _createBlock(_component_BlockUser, { key: 0 }))
                  : _createCommentVNode("", true),
                (this.isStydentBanned)
                  ? (_openBlock(), _createBlock(_component_UnblockUser, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _withDirectives(_createVNode(_component_Calendar, {
                class: "students__calendar students__calendar-banned-user",
                "select-date-fill": this.selectDateFillStydentBanned,
                "selected-new-date": this.selectedNewDateStydentBanned
              }, null, 8, ["select-date-fill", "selected-new-date"]), [
                [_vShow, _ctx.isCalendarVisibleStydentBanned]
              ])
            ])), [
              [_directive_click_outside, _ctx.closeCalendarStydentBanned]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              onClick: ($event: any) => (_ctx.activeTab = index),
              class: _normalizeClass({ activeTab: _ctx.activeTab === index })
            }, _toDisplayString(tab), 11, _hoisted_32))
          }), 128))
        ]),
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createTextVNode(" Subscription status: " + _toDisplayString(this.studentData?.subscription?.status) + " ", 1),
                (this.studentData?.subscription === null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_35, "Inactive"))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[60] || (_cache[60] = _createTextVNode(" ID ")),
                  _createElementVNode("div", _hoisted_39, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getPaymentsList('?search=', 'id')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getPaymentsList('?search=', '-id'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _cache[61] || (_cache[61] = _createTextVNode(" Payment date ")),
                  _createElementVNode("div", _hoisted_41, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getPaymentsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getPaymentsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _cache[62] || (_cache[62] = _createTextVNode(" Status ")),
                  _createElementVNode("div", _hoisted_43, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getPaymentsList('?search=', 'status')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getPaymentsList('?search=', '-status'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _cache[63] || (_cache[63] = _createTextVNode(" Activation date ")),
                  _createElementVNode("div", _hoisted_45, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getPaymentsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getPaymentsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_46, [
                  _cache[64] || (_cache[64] = _createTextVNode(" Deactivation date ")),
                  _createElementVNode("div", _hoisted_47, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (
                    this.getPaymentsList('?search=', 'subscription_end_date')
                  ))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (
                    this.getPaymentsList('?search=', '-subscription_end_date')
                  )),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _cache[65] || (_cache[65] = _createTextVNode(" Activated by ")),
                  _createElementVNode("div", _hoisted_49, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getPaymentsList('?search=', 'payment_for')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getPaymentsList('?search=', '-payment_for'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _cache[66] || (_cache[66] = _createTextVNode(" Type ")),
                  _createElementVNode("div", _hoisted_51, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getPaymentsList('?search=', 'type')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (this.getPaymentsList('?search=', '-type'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anayticsData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "main-table__body",
                  key: item,
                  onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.selectInstructor && _ctx.selectInstructor(...args)))
                }, [
                  _createElementVNode("div", _hoisted_52, _toDisplayString(item?.id), 1),
                  _createElementVNode("div", _hoisted_53, _toDisplayString(item?.payment_date?.split("T")[0]), 1),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(item?.status), 1),
                  _createElementVNode("div", _hoisted_55, _toDisplayString(item?.payment_date?.split("T")[0]), 1),
                  _createElementVNode("div", _hoisted_56, _toDisplayString(item?.subscription_end_date?.split("T")[0]), 1),
                  _createElementVNode("div", _hoisted_57, [
                    _createTextVNode(_toDisplayString(item?.activated_by?.type) + " ", 1),
                    (item?.activated_by?.type === 'Admin')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(item?.activated_by?.email), 1))
                      : _createCommentVNode("", true),
                    (item?.activated_by?.type === 'Admin')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                          _createTextVNode(_toDisplayString(item?.activated_by?.type) + " ", 1),
                          (item?.activated_by?.type === 'Admin')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(item?.activated_by?.email), 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_61, _toDisplayString(item?.payment_for), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _cache[67] || (_cache[67] = _createElementVNode("div", { class: "main-table__expiration-date-title" }, "Expiration date", -1)),
              _createElementVNode("div", _hoisted_63, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_64, [
                  _createElementVNode("div", {
                    class: "students__data-range",
                    onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                  }, [
                    (this.selectedNewDate === null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_65, _toDisplayString(this.selectedDatePlacholder), 1))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(this.selectedNewDate) + " ", 1),
                    _createVNode(_component_SelectArrow, {
                      class: _normalizeClass({ isOpenSelectArrow: _ctx.isCalendarVisible })
                    }, null, 8, ["class"])
                  ]),
                  _withDirectives(_createVNode(_component_Calendar, {
                    class: "students__calendar",
                    "select-date-fill": this.selectDateFill,
                    "selected-new-date": this.selectedNewDate
                  }, null, 8, ["select-date-fill", "selected-new-date"]), [
                    [_vShow, _ctx.isCalendarVisible]
                  ]),
                  (this.errorSubscribe)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                        _createVNode(_component_AlertBlock, {
                          message: this.errorSubscribe.expiration_date
                        }, null, 8, ["message"])
                      ]))
                    : _createCommentVNode("", true),
                  (this.errorMessageSubscribe)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_67, _toDisplayString(this.errorMessageSubscribe), 1))
                    : _createCommentVNode("", true)
                ])), [
                  [_directive_click_outside, _ctx.closeCalendar]
                ]),
                _createElementVNode("div", {
                  class: "students__active-subscription-button",
                  onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.subscribe && _ctx.subscribe(...args)))
                }, " Activate subscription ")
              ]),
              (this.studentData.subscription === null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_68, " Unsubscribe "))
                : _createCommentVNode("", true),
              (this.studentData.subscription !== null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "students__unsubscribe-button",
                    onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.openPopupUnsibscribe && _ctx.openPopupUnsibscribe(...args)))
                  }, " Unsubscribe "))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
              _createElementVNode("div", _hoisted_70, [
                _cache[68] || (_cache[68] = _createElementVNode("div", { class: "main-table__expiration-date-title" }, "Period", -1)),
                _createElementVNode("div", _hoisted_71, [
                  _createVNode(_component_Select, {
                    data: _ctx.dataPeriod,
                    "select-name": "Month",
                    class: "students__select",
                    title: 'title',
                    onOptionSelected: _ctx.handleOptionSelected
                  }, null, 8, ["data", "onOptionSelected"]),
                  _createElementVNode("div", {
                    class: "students__active-subscription-button",
                    onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.refunds && _ctx.refunds(...args)))
                  }, " Issue a return ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
              _createElementVNode("div", _hoisted_73, [
                _cache[70] || (_cache[70] = _createElementVNode("div", { class: "main-table__head-id" }, "ID", -1)),
                _createElementVNode("div", _hoisted_74, [
                  _cache[69] || (_cache[69] = _createTextVNode(" Refunds date ")),
                  _createElementVNode("div", _hoisted_75, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (this.refundsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[24] || (_cache[24] = ($event: any) => (this.refundsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refundsData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "main-table__body",
                  key: item,
                  onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.selectInstructor && _ctx.selectInstructor(...args)))
                }, [
                  _createElementVNode("div", _hoisted_76, _toDisplayString(item?.id), 1),
                  _createElementVNode("div", _hoisted_77, _toDisplayString(item?.payment_date?.split("T")[0]), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
              _createElementVNode("div", _hoisted_79, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_80, [
                  _createElementVNode("div", {
                    class: "calendar__data-range",
                    onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                  }, [
                    _cache[71] || (_cache[71] = _createTextVNode(" Date range ")),
                    _createVNode(_component_SelectArrow, {
                      class: _normalizeClass({ isOpenSelectArrow: _ctx.isCalendarVisible })
                    }, null, 8, ["class"])
                  ]),
                  _withDirectives(_createVNode(_component_Calendar, {
                    class: "calendar__calendar",
                    "select-date-fill": this.selectDateFill,
                    "selected-new-date": this.selectedNewDate
                  }, null, 8, ["select-date-fill", "selected-new-date"]), [
                    [_vShow, _ctx.isCalendarVisible]
                  ])
                ])), [
                  [_directive_click_outside, _ctx.closeCalendar]
                ]),
                _createVNode(_component_Select, {
                  data: _ctx.dataLevels,
                  "select-name": "Event type",
                  class: "students__select students__select-event",
                  title: 'title',
                  onOptionSelected: _cache[27] || (_cache[27] = 
                (option) => _ctx.handleOptionSelected('level_id', option)
              )
                }, null, 8, ["data"])
              ]),
              (_ctx.activeTab === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                    _createElementVNode("div", _hoisted_82, [
                      _createElementVNode("div", _hoisted_83, [
                        _cache[72] || (_cache[72] = _createTextVNode(" Date ")),
                        _createElementVNode("div", _hoisted_84, [
                          _createVNode(_component_SortArrow, {
                            onClick: _cache[28] || (_cache[28] = ($event: any) => (this.refundsList('?search=', 'date')))
                          }),
                          _createVNode(_component_SortArrow, {
                            onClick: _cache[29] || (_cache[29] = ($event: any) => (this.refundsList('?search=', '-date'))),
                            class: "main-table__sort-bottom"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_85, [
                        _cache[73] || (_cache[73] = _createTextVNode(" Notification ")),
                        _createElementVNode("div", _hoisted_86, [
                          _createVNode(_component_SortArrow, {
                            onClick: _cache[30] || (_cache[30] = ($event: any) => (this.refundsList('?search=', 'notification')))
                          }),
                          _createVNode(_component_SortArrow, {
                            onClick: _cache[31] || (_cache[31] = ($event: any) => (this.refundsList('?search=', '-notification'))),
                            class: "main-table__sort-bottom"
                          })
                        ])
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refundsData, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "main-table__body",
                        key: item,
                        onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.selectInstructor && _ctx.selectInstructor(...args)))
                      }, [
                        _createElementVNode("div", _hoisted_87, _toDisplayString(item?.id), 1),
                        _createElementVNode("div", _hoisted_88, _toDisplayString(item?.payment_date?.split("T")[0]), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[74] || (_cache[74] = _createElementVNode("div", { class: "students__popup-title" }, "Are you sure?", -1)),
            _createElementVNode("div", _hoisted_89, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.deleteStudent && _ctx.deleteStudent(...args)))
              }, "Yes"),
              _createElementVNode("div", {
                class: "students__popup-button-no",
                onClick: _cache[34] || (_cache[34] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalBlockUser)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseModalBlockUser
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_90, " Are you sure? " + _toDisplayString(this.selectedNewDateStydentBanned), 1),
            _createElementVNode("div", _hoisted_91, [
              (!this.isStydentBanned)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "students__popup-button-yes",
                    onClick: _cache[35] || (_cache[35] = 
//@ts-ignore
(...args) => (_ctx.blockStudent && _ctx.blockStudent(...args)))
                  }, " Yes "))
                : _createCommentVNode("", true),
              (this.isStydentBanned)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "students__popup-button-yes",
                    onClick: _cache[36] || (_cache[36] = 
//@ts-ignore
(...args) => (_ctx.unblockStudent && _ctx.unblockStudent(...args)))
                  }, " Yes "))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "students__popup-button-no",
                onClick: _cache[37] || (_cache[37] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalBlockUser && _ctx.handleCloseModalBlockUser(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalRefunds)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 2,
          "on-close": _ctx.handleCloseModalRefunds
        }, {
          default: _withCtx(() => [
            _cache[75] || (_cache[75] = _createElementVNode("div", { class: "students__popup-title" }, "Refund processed successfully", -1)),
            _createElementVNode("div", _hoisted_92, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[38] || (_cache[38] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalRefunds && _ctx.handleCloseModalRefunds(...args)))
              }, " Ok ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalSubscribed)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 3,
          "on-close": _ctx.handleCloseModalSubscribed
        }, {
          default: _withCtx(() => [
            _cache[76] || (_cache[76] = _createElementVNode("div", { class: "students__popup-title" }, "You have successfully subscribed", -1)),
            _createElementVNode("div", _hoisted_93, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[39] || (_cache[39] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalSubscribed && _ctx.handleCloseModalSubscribed(...args)))
              }, " Ok ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isPopupUnsibscribe)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 4,
          "on-close": _ctx.handleCloseModalUnsubscribed
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_94, [
              _createTextVNode(" The subscription is valid until to " + _toDisplayString(this.selectedDatePlacholder) + " ", 1),
              _cache[77] || (_cache[77] = _createElementVNode("br", null, null, -1)),
              _cache[78] || (_cache[78] = _createTextVNode(" Then you will be unsubscribed "))
            ]),
            _createElementVNode("div", _hoisted_95, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[40] || (_cache[40] = 
//@ts-ignore
(...args) => (_ctx.unsubscribe && _ctx.unsubscribe(...args)))
              }, "Ok")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalChooseInstructorNewStudent)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 5,
          "on-close": _ctx.handleCloseModalChooseInstructorNewStudent,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_96, [
              (this.activeStep !== '4')
                ? (_openBlock(), _createElementBlock("div", _hoisted_97, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-first", { 'active-step': this.activeStep === '1' }])
                    }, " Student details ", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-second", { 'active-step': this.activeStep === '2' }])
                    }, " Choose Instructor ", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-third", { 'active-step': this.activeStep === '3' }])
                    }, " Generate code ", 2)
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_98, [
                    _cache[82] || (_cache[82] = _createElementVNode("div", { class: "students__create-step-title" }, "Student details", -1)),
                    _createElementVNode("div", _hoisted_99, [
                      _createElementVNode("div", _hoisted_100, [
                        _createElementVNode("div", null, [
                          _cache[79] || (_cache[79] = _createElementVNode("div", { class: "students__photo-title" }, "Photo", -1)),
                          _createElementVNode("label", _hoisted_101, [
                            (this.isPreview)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: _ctx.imagePreview,
                                  class: "students__images-preview"
                                }, null, 8, _hoisted_102))
                              : (_openBlock(), _createElementBlock("div", _hoisted_103, [
                                  _createVNode(_component_UploadPhoto)
                                ])),
                            _createElementVNode("input", {
                              type: "file",
                              id: "file",
                              ref: "file",
                              accept: ".png, .jpg, .jpeg",
                              onChange: _cache[41] || (_cache[41] = ($event: any) => (_ctx.handleFileUpload()))
                            }, null, 544)
                          ])
                        ])
                      ]),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.image
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_105, [
                        _cache[80] || (_cache[80] = _createElementVNode("label", {
                          for: "full_name",
                          class: "form-group-label"
                        }, "Full Name ", -1)),
                        _createElementVNode("div", _hoisted_106, [
                          _createVNode(_component_CreateStudent),
                          _createVNode(_component_Field, {
                            class: "form-control",
                            type: "text",
                            name: "full_name",
                            modelValue: this.fullName,
                            "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((this.fullName) = $event))
                          }, null, 8, ["modelValue"]),
                          (this.errorInstructor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_107, [
                                _createVNode(_component_AlertBlock, {
                                  message: this.errorInstructor.full_name
                                }, null, 8, ["message"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("button", {
                        onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.nextStep('2'))),
                        class: "students__button-next students__button-next-boottom"
                      }, _cache[81] || (_cache[81] = [
                        _createElementVNode("span", null, "Next", -1)
                      ]))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '2')
                ? (_openBlock(), _createElementBlock("div", _hoisted_108, [
                    _cache[83] || (_cache[83] = _createElementVNode("div", { class: "students__create-step-title" }, "Choose instructor", -1)),
                    _createElementVNode("div", _hoisted_109, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        placeholder: "Search",
                        "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.searchDataInstructors) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.searchDataInstructors]
                      ]),
                      _createVNode(_component_Search)
                    ]),
                    _createElementVNode("div", _hoisted_110, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorsData, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.id
                        }, [
                          _createElementVNode("label", {
                            for: item.id,
                            class: "custom-checkbox"
                          }, [
                            _createElementVNode("input", {
                              id: item.id,
                              name: item.full_name,
                              onClick: ($event: any) => (_ctx.selectInstructors(item.id)),
                              type: "checkbox"
                            }, null, 8, _hoisted_112),
                            _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                          ], 8, _hoisted_111)
                        ]))
                      }), 128)),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_113, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.instructor_id
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "students__button-back",
                        onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.prewStep('1')))
                      }, "Back"),
                      _createElementVNode("div", {
                        class: "students__button-next",
                        onClick: _cache[46] || (_cache[46] = ($event: any) => (_ctx.createAdditionalStudent()))
                      }, " Next ")
                    ])
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '3')
                ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
                    _cache[85] || (_cache[85] = _createElementVNode("div", { class: "students__create-step-title" }, "Generate code", -1)),
                    _createElementVNode("div", _hoisted_115, [
                      (!this.imagePreview)
                        ? (_openBlock(), _createBlock(_component_PhotoMock, {
                            key: 0,
                            class: "students__photo-mock"
                          }))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: this.imagePreview,
                            alt: this.studentData?.full_name,
                            class: "students__photo-mock"
                          }, null, 8, _hoisted_116))
                    ]),
                    _createElementVNode("div", null, [
                      _cache[84] || (_cache[84] = _createElementVNode("div", { class: "students__title" }, "Full Name", -1)),
                      _createElementVNode("div", _hoisted_117, _toDisplayString(this.studentData?.full_name), 1)
                    ]),
                    _cache[86] || (_cache[86] = _createElementVNode("div", null, [
                      _createElementVNode("div", { class: "students__title" }, "Instructors"),
                      _createElementVNode("div", { class: "students__value" }, "Jack Torrens")
                    ], -1)),
                    _createElementVNode("div", {
                      class: "students__button-back",
                      onClick: _cache[47] || (_cache[47] = ($event: any) => (_ctx.prewStep('2')))
                    }, "Back"),
                    _createElementVNode("div", {
                      class: "students__button-next students__button-create-student",
                      onClick: _cache[48] || (_cache[48] = ($event: any) => (_ctx.getLinkAuthQrCode('4')))
                    }, " Create student and generate link ")
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '4')
                ? (_openBlock(), _createElementBlock("div", _hoisted_118, [
                    _cache[87] || (_cache[87] = _createElementVNode("div", { class: "students__created-link-title" }, "Link is generated!", -1)),
                    _createElementVNode("div", _hoisted_119, _toDisplayString(this.linkToQr), 1),
                    _createElementVNode("div", {
                      class: "students__copy-code-button-popup",
                      onClick: _cache[49] || (_cache[49] = ($event: any) => (_ctx.copyText()))
                    }, " Copy link ")
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalConvertToIndividualStudent)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 6,
          "on-close": _ctx.handleCloseModalConvertToIndividualStudent,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_120, [
              (this.activeStep === '1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_121, [
                    _cache[91] || (_cache[91] = _createElementVNode("div", { class: "students__create-step-title" }, " Convert to Individual student ", -1)),
                    _createVNode(_component_Form, {
                      onSubmit: _ctx.convertAndGenetare,
                      class: "students__form"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_122, [
                          _cache[88] || (_cache[88] = _createElementVNode("label", {
                            for: "phone_number",
                            class: "form-group-label"
                          }, "Phone Number ", -1)),
                          _createElementVNode("div", _hoisted_123, [
                            _createVNode(_component_PhoneIcon),
                            _createVNode(_component_Field, {
                              class: "form-control",
                              type: "text",
                              name: "phone_number",
                              modelValue: this.phone,
                              "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((this.phone) = $event))
                            }, null, 8, ["modelValue"]),
                            (this.errorInstructor)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_124, [
                                  _createVNode(_component_AlertBlock, {
                                    message: this.errorInstructor.phone
                                  }, null, 8, ["message"])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_125, [
                          _cache[89] || (_cache[89] = _createElementVNode("label", {
                            for: "email",
                            class: "form-group-label"
                          }, "Email ", -1)),
                          _createElementVNode("div", _hoisted_126, [
                            _createVNode(_component_EmailIcon),
                            _createVNode(_component_Field, {
                              class: "form-control",
                              type: "text",
                              name: "email",
                              modelValue: this.email,
                              "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((this.email) = $event))
                            }, null, 8, ["modelValue"]),
                            (this.errorInstructor)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_127, [
                                  _createVNode(_component_AlertBlock, {
                                    message: this.errorInstructor.email
                                  }, null, 8, ["message"])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _cache[90] || (_cache[90] = _createElementVNode("button", { class: "students__button-convert" }, [
                          _createElementVNode("span", null, "Covert and generate link")
                        ], -1))
                      ]),
                      _: 1
                    }, 8, ["onSubmit"])
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '5')
                ? (_openBlock(), _createElementBlock("div", _hoisted_128, [
                    _cache[92] || (_cache[92] = _createElementVNode("div", { class: "students__create-step-title" }, " Convert to Individual student ", -1)),
                    _cache[93] || (_cache[93] = _createElementVNode("div", { class: "students__created-link-title" }, "Link is generated!", -1)),
                    _cache[94] || (_cache[94] = _createElementVNode("div", { class: "students__popup-link-text" }, "Copy link", -1)),
                    _createElementVNode("div", {
                      class: "students__copy-code-button-popup",
                      onClick: _cache[52] || (_cache[52] = ($event: any) => (_ctx.copyText()))
                    }, " Copy link ")
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_129, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}