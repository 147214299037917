
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";

export default defineComponent({
  name: "Admin",
  data() {
    return {
      isModalDelete: false,
      adminData: {},
      adminId: null,
      errorInstructor: null,
      errorInstructors: null,
      generateCode: "",
      errorGenerateCode: null,
    };
  },
  components: {
    ArrowBack,
    Edit,
    Delete,
    DefaultPopup,
    AlertBlock,
  },
  mounted() {
    this.adminId = this.$route.query.admin;
    this.getAdmin(this.adminId);
  },
  methods: {
    openDeletePoup(id) {
      this.adminId = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
      this.errorInstructors = null;
    },
    deleteAdmin() {
      this.$store.dispatch("admins/deleteAdmin", this.adminId).then(
        () => {
          this.adminId = null;
          this.$router.push("/super-admin/school-admin-list");
        },
        (error) => {
          this.errorInstructors = error.response.data;
        }
      );
    },
    editInstructor(id) {
      this.$router.push(
        "/super-admin/creare-school-admin?edit=true&admin=" + id
      );
    },
    getAdmin(id) {
      this.$store.dispatch("admins/getAdmin", id).then(
        (res) => {
          this.adminData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
  },
});
