
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";
import BlockUser from "@/assets/svg/block-user.svg?inline";
import UnblockUser from "@/assets/svg/unblock-user.svg?inline";
import AdditionalStudentIcon from "@/assets/svg/additional-student.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import Calendar from "@/components/molecules/OneDateCalendar.vue";
import vClickOutside from "click-outside-vue3";
import Select from "@/components/atoms/CustomSelect.vue";
import CreateStudent from "@/assets/svg/create-student-name.svg?inline";
import EmailIcon from "@/assets/svg/create-student-email-icon.svg?inline";
import PhoneIcon from "@/assets/svg/login-phone-icon.svg?inline";
import UploadPhoto from "@/assets/svg/upload-photo.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import authHeader from "@/services/auth-header";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "Student",
  data() {
    return {
      isModalDelete: false,
      isModalBlockUser: false,
      isStydentBanned: false,
      studentData: {},
      studentId: null,
      activeTab: 0,
      tabs: ["Payments Log", "Subscription management", "Refunds"],
      isCalendarVisible: false,
      refundsData: {},
      dataPeriod: [
        {
          title: "Month",
          value: "month",
        },
        {
          title: "2 months",
          value: "2_months",
        },
        {
          title: "3 months",
          value: "3_months",
        },
      ],
      periodSelected: "month",
      selectedNewDate: null,
      errorSubscribe: null,
      errorMessageSubscribe: null,
      anayticsData: {},
      isModalRefunds: false,
      isModalSubscribed: false,
      selectedDatePlacholder: "Date",
      isPopupUnsibscribe: false,
      isModalChooseInstructorNewStudent: false,
      isModalConvertToIndividualStudent: false,
      activeStep: "1",
      isPreview: null,
      fullName: "",
      phone: null,
      email: null,
      errorInstructor: null,
      instructorsData: {},
      searchDataInstructors: "",
      inviteInstructorsData: [],
    };
  },
  components: {
    ArrowBack,
    PhotoMock,
    Edit,
    Delete,
    BlockUser,
    UnblockUser,
    Select,
    DefaultPopup,
    SortArrow,
    SelectArrow,
    Calendar,
    AlertBlock,
    AdditionalStudentIcon,
    CreateStudent,
    EmailIcon,
    PhoneIcon,
    Form,
    Field,
    Search,
    UploadPhoto,
  },
  mounted() {
    this.studentId = this.$route.query.student;
    this.getStudent(this.studentId);
    this.refundsList(this.studentId);
    this.getPaymentsList();
    this.getInstructors();
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    getStudent(id) {
      this.$store.dispatch("students/getStudent", id).then(
        (res) => {
          this.studentData = res.data;
          this.isStydentBanned = this.studentData.banned;
          if (this.studentData.subscription !== null) {
            const date = new Date(this.studentData.subscription.period.to);
            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0");
            const year = date.getUTCFullYear();
            this.selectedDatePlacholder = `${day}.${month}.${year}`;
          }
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getPaymentsList(params, sort) {
      if (sort === undefined) {
        sort = "id";
      }
      params = {
        id: this.studentId,
        string: "?sort=" + sort,
      };
      this.$store.dispatch("students/getPaymentsList", params).then(
        (res) => {
          this.anayticsData = res.data;
          this.anayticsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    openDeletePoup() {
      this.isModalDelete = true;
    },
    openBlockUserPopup() {
      this.isModalBlockUser = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
    },
    handleCloseModalBlockUser() {
      this.isModalBlockUser = false;
    },
    blockStudent() {
      this.$store.dispatch("students/banStudent", this.studentId).then(
        (res) => {
          this.getStudent(this.studentId);
          this.isModalBlockUser = false;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    unblockStudent() {
      this.$store.dispatch("students/unbanStudent", this.studentId).then(
        (res) => {
          this.getStudent(this.studentId);
          this.isModalBlockUser = false;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    subscribe(data) {
      data = {
        id: this.studentId,
        expiration_date: this.selectedNewDate,
      };
      this.$store.dispatch("students/subscribe", data).then(
        (res) => {
          this.subscribeData = res.data;
          this.getStudent(this.studentId);
          this.isModalSubscribed = true;
        },
        (error) => {
          this.errorSubscribe = error.response.data.errors;
          if (error.response.data.errors === undefined) {
            this.errorMessageSubscribe = error.response.data.message;
          }
        }
      );
    },
    unsubscribe(data) {
      data = {
        id: this.studentId,
      };
      this.$store.dispatch("students/unsubscribe", data).then(
        (res) => {
          this.isPopupUnsibscribe = false;
          this.getStudent(this.studentId);
        },
        (error) => {
          this.errorUnsubscribe = error.response.data.errors;
        }
      );
    },
    refunds(data) {
      data = {
        id: this.studentId,
        period: this.periodSelected,
      };
      this.$store.dispatch("students/refunds", data).then(
        (res) => {
          console.log(res);
          this.isModalRefunds = true;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    refundsList(data, sort) {
      if (sort === undefined) {
        sort = "id";
      }
      data = {
        id: this.studentId,
        string: "?sort=" + sort,
      };
      this.$store.dispatch("students/refundsList", data).then(
        (res) => {
          this.refundsData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    deleteStudent() {
      this.$store.dispatch("students/deleteStudent", this.studentId).then(
        (res) => {
          this.$router.push("/admin/students");
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    handleOptionSelected(selectedOption) {
      this.periodSelected = selectedOption.value;
    },
    editstudent() {
      this.$router.push("/admin/edit-student?student=" + this.studentId);
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const Month = selectedDate.getMonth() + 1;
      let monthFormat = "";
      if (Month < 10) {
        monthFormat = "0" + (selectedDate.getMonth() + 1);
      } else monthFormat = (selectedDate.getMonth() + 1).toString();
      const Day = selectedDate.getDate();
      let dayFormat = "";
      if (Day < 10) {
        dayFormat = "0" + selectedDate.getDate();
      } else dayFormat = selectedDate.getDate().toString();
      this.selectedNewDate =
        selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
      this.isCalendarVisible = false;
      this.errorSubscribe = null;
      this.errorMessageSubscribe = null;
    },
    handleCloseModalRefunds() {
      this.isModalRefunds = false;
    },
    handleCloseModalSubscribed() {
      this.isModalSubscribed = false;
    },
    openPopupUnsibscribe() {
      this.isPopupUnsibscribe = true;
    },
    handleCloseModalUnsubscribed() {
      this.isPopupUnsibscribe = false;
    },
    openPopupChoosenInstructorNewStudent(id) {
      this.studentId = id;
      this.isModalChooseInstructorNewStudent = true;
    },
    handleCloseModalChooseInstructorNewStudent() {
      this.isModalChooseInstructorNewStudent = false;
    },
    openPopupConvertToIndividualStudent(id) {
      this.studentId = id;
      this.isModalConvertToIndividualStudent = true;
    },
    handleCloseModalConvertToIndividualStudent() {
      this.isModalConvertToIndividualStudent = false;
    },
    nextStep(step) {
      this.activeStep = step;
    },
    prewStep(step) {
      this.activeStep = step;
    },
    createStudent(step) {
      this.activeStep = step;
    },
    getInstructors(params) {
      params = "?search=" + this.searchDataInstructors;
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.instructorsData = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    selectInstructors(id) {
      this.inviteInstructorsData.push(id);
    },
    createAdditionalStudent() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("full_name", this.fullName);
      formData.append("instructor_id", this.inviteInstructorsData);
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      this.axios
        .post(
          API_URL + `admin/${this.$route.query.student}/children`,
          formData,
          {
            headers: authHeader(),
          }
        )
        .then(
          (res) => {
            this.nextStep("3");
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
          }
        );
    },
    convertAndGenetare() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      this.axios
        .patch(
          API_URL + `admin/${this.$route.query.student}/convert-to-normal`,
          {
            phone: this.phone,
            email: this.email,
          },
          {
            headers: authHeader(),
          }
        )
        .then(
          () => {
            this.nextStep("5");
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
          }
        );
    },
    handleFileUpload() {
      this.isPreview = false
      this.imagePreview = null
      this.errorInstructor = null;
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
  },
});
