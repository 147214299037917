import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "edit-stydent" }
const _hoisted_2 = { class: "edit-stydent__images-container" }
const _hoisted_3 = { class: "edit-stydent__images-preview-wrap" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "edit-stydent__photo-block" }
const _hoisted_8 = { class: "edit-stydent__selects-group" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "form-group edit-stydent__instructors-wrap" }
const _hoisted_20 = {
  key: 0,
  class: "edit-stydent__container-instructors"
}
const _hoisted_21 = {
  for: "instructor",
  class: "edit-stydent__instructors-search-container"
}
const _hoisted_22 = ["for"]
const _hoisted_23 = ["id", "name", "onClick", "checked"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Field = _resolveComponent("Field")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_Search = _resolveComponent("Search")!
  const _component_PinSvg = _resolveComponent("PinSvg")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/students",
      class: "edit-stydent__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "edit-stydent__title" }, "Edit", -1)),
    _createVNode(_component_Form, {
      onSubmit: _ctx.editStudent,
      class: "edit-stydent__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!this.isPreview && this.studentData?.image === undefined)
              ? (_openBlock(), _createBlock(_component_PhotoMock, { key: 0 }))
              : _createCommentVNode("", true),
            (!this.isPreview && this.studentData?.image === null)
              ? (_openBlock(), _createBlock(_component_PhotoMock, { key: 1 }))
              : _createCommentVNode("", true),
            (this.isPreview && this.studentData?.image === null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.imagePreview,
                  class: "edit-stydent__images-preview"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (this.isPreview && this.studentData?.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: _ctx.imagePreview,
                  class: "edit-stydent__images-preview"
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            (!this.isPreview && this.studentData?.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 4,
                  src: this.studentData?.image,
                  class: "student__photo"
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "edit-stydent__upload-photo first-button" }, "Upload", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "file",
                ref: "file",
                accept: ".png, .jpg, .jpeg",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload()))
              }, null, 544),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "edit-stydent__upload-info" }, " Picture must be 720 px * 470 px and no more than 10 MB ", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[8] || (_cache[8] = _createElementVNode("label", {
              for: "phone_number",
              class: "form-group-label"
            }, "Level ", -1)),
            _createVNode(_component_Select, {
              data: _ctx.levelsData,
              "select-name": _ctx.defaultLevelName,
              class: "edit-stydent__select edit-stydent__level-select",
              title: 'title',
              onOptionSelected: _ctx.handleOptionSelectedLevel
            }, null, 8, ["data", "select-name", "onOptionSelected"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[9] || (_cache[9] = _createElementVNode("label", {
              for: "phone_number",
              class: "form-group-label"
            }, "Skill ", -1)),
            _createVNode(_component_Select, {
              data: _ctx.skillsData,
              "select-name": _ctx.defaultSkillName,
              class: "edit-stydent__select edit-stydent__level-scill",
              title: 'skill_order',
              onOptionSelected: _ctx.handleOptionSelectedSkill
            }, null, 8, ["data", "select-name", "onOptionSelected"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "full_name",
            class: "form-group-label"
          }, "Full Name ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "full_name",
            modelValue: this.full_name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.full_name) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.full_name
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "email",
            class: "form-group-label"
          }, "Email ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "email",
            modelValue: this.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.email) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.email
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[12] || (_cache[12] = _createElementVNode("label", {
            for: "phone_number",
            class: "form-group-label"
          }, "Phone Number ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "phone_number",
            modelValue: this.phone_number,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.phone_number) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.phone
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            for: "phone_number",
            class: "form-group-label"
          }, "Swimming ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataSwimming,
            "select-name": "Swimming",
            class: "edit-stydent__select",
            onOptionSelected: _ctx.handleOptionSelected
          }, null, 8, ["data", "onOptionSelected"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            for: "phone_number",
            class: "form-group-label"
          }, "School ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataSchool,
            "select-name": "SRA",
            class: "edit-stydent__select",
            onOptionSelected: _ctx.handleOptionSelected
          }, null, 8, ["data", "onOptionSelected"])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_19, [
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            for: "instructor",
            class: "form-group-label"
          }, "Instructor ", -1)),
          _createElementVNode("div", {
            class: "form-control edit-stydent__select-multi-title",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openMultiSelect && _ctx.openMultiSelect(...args)))
          }, [
            _cache[15] || (_cache[15] = _createTextVNode(" Instructor ")),
            _createVNode(_component_SelectArrow, {
              class: _normalizeClass(["edit-stydent__instructors-search-arrow", { selectOpenSvg: this.searchDataBody }])
            }, null, 8, ["class"])
          ]),
          (this.searchDataBody)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    name: "instructor",
                    placeholder: 'Name',
                    autocomplete: "off",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.searchData) = $event))
                  }, null, 512), [
                    [_vModelText, this.searchData]
                  ]),
                  _createVNode(_component_Search, { class: "edit-stydent__instructors-search" })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "edit-stydent__instructor"
                  }, [
                    _createElementVNode("label", {
                      for: item.id,
                      class: "custom-checkbox"
                    }, [
                      _createElementVNode("input", {
                        id: item.id,
                        name: item.full_name,
                        onClick: ($event: any) => (_ctx.setInstructor(item.id, item.full_name)),
                        checked: _ctx.isChecked(item.id),
                        type: "checkbox"
                      }, null, 8, _hoisted_23),
                      _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                    ], 8, _hoisted_22)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.instructor
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ])), [
          [_directive_click_outside, _ctx.closeMultiSelect]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reversedInstructorName, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "edit-stydent__instructor-selected-container"
          }, [
            _createTextVNode(_toDisplayString(item.full_name) + " ", 1),
            _createElementVNode("div", {
              class: "edit-stydent__instructor-pin-container",
              onClick: ($event: any) => (_ctx.deleteStident(item.id))
            }, [
              _createVNode(_component_PinSvg)
            ], 8, _hoisted_25)
          ]))
        }), 128)),
        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "form-group" }, [
          _createElementVNode("button", { class: "edit-stydent__button" }, [
            _createElementVNode("span", null, "Save")
          ])
        ], -1))
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}