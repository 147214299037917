import StudentsService from '@/services/students.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const students = {
  namespaced: true,
  state: initialState,
  actions: {
    getStudents ({ commit }: any, params: string) {
      return StudentsService.getStudents(params).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getStudent ({ commit }: any, id: string) {
      return StudentsService.getStudent(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getPaymentsList ({ commit }: any, params: any) {
      return StudentsService.getPaymentsList(params).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteStudent ({ commit }: any, id: string) {
      return StudentsService.deleteStudent(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    banStudent ({ commit }: any, id: string) {
      return StudentsService.banStudent(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    unbanStudent ({ commit }: any, id: string) {
      return StudentsService.unbanStudent(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    refunds ({ commit }: any, data: any) {
      return StudentsService.refunds(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    subscribe ({ commit }: any, data: any) {
      return StudentsService.subscribe(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    unsubscribe ({ commit }: any, data: any) {
      return StudentsService.unsubscribe(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    refundsList ({ commit }: any, data: any) {
      return StudentsService.refundsList(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getInviteCode ({ commit }: any, data: any) {
      return StudentsService.getInviteCode(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setInviteCode ({ commit }: any, data: any) {
      return StudentsService.setInviteCode(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    attachInstructors ({ commit }: any, data: any) {
      return StudentsService.attachInstructors(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    detachInstructors ({ commit }: any, data: any) {
      return StudentsService.detachInstructors(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getLinkSetPassword ({ commit }: any, id: any) {
      return StudentsService.getLinkSetPassword(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getLinkAuthQrCode ({ commit }: any, id: any) {
      return StudentsService.getLinkAuthQrCode(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
