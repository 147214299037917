
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
// import SortArrow from '@/assets/svg/sort-arrow.svg?inline'

export default defineComponent({
  name: "Instructor",
  data() {
    return {
      isModalDelete: false,
      schoolData: {},
      schoolId: "",
      errorInstructor: null,
      errorInstructors: null,
      generateCode: "",
      errorGenerateCode: null,
    };
  },
  components: {
    ArrowBack,
    PhotoMock,
    Edit,
    Delete,
    DefaultPopup,
    AlertBlock,
    // SortArrow
  },
  mounted() {
    this.schoolId = this.$route.query.school;
    this.getSchool(this.schoolId);
  },
  methods: {
    openDeletePoup(id) {
      this.idInstructor = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
      this.errorInstructors = null;
    },
    deleteSchool() {
      this.$store.dispatch("admins/deleteSchool", this.schoolId).then(
        () => {
          this.$router.push("/super-admin/school-list");
          this.isModalDelete = false;
          this.schoolId = null;
        },
        (error) => {
          this.errorSchool = error.response.data;
        }
      );
    },
    editSchool(id) {
      this.$router.push("/super-admin/creare-school?edit=true&school=" + id);
    },
    getSchool(id) {
      this.$store.dispatch("admins/getSchool", id).then(
        (res) => {
          this.schoolData = res.data;
        },
        (error) => {
          this.errorSchool = error.response.data.errors;
        }
      );
    },
    generateInviteCode(data) {
      data = [];
      data.push(this.schoolId);
      this.$store.dispatch("instructors/generateInviteCode", data).then(
        (res) => {
          this.generateCode = res.data;
        },
        (error) => {
          this.errorGenerateCode = error.response.data.errors;
        }
      );
    },
    copyText() {
      const textToCopy = this.generateCode.code;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
  },
});
